import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/games-icon/home-icon.png";
import CasinoIcon from "./../../assets/images/games-icon/casino-icon.png";
import InplayIcon from "./../../assets/images/games-icon/inplay-icon.png";
import CricketIcon from "./../../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "./../../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "./../../assets/images/games-icon/tennis-icon.png";
import GolfIcon from "./../../assets/images/games-icon/golf-icon.png";
import RugbyIcon from "./../../assets/images/games-icon/rugby-icon.png";
import BoxingIcon from "./../../assets/images/games-icon/boxing-icon.png";
import horseracingIcon from "./../../assets/images/games-icon/horseracing-icon.png";
import EsportsIcon from "./../../assets/images/games-icon/esports-icon.png";
import VolleyballIcon from "./../../assets/images/games-icon/volleyball-icon.png";
import CyclingIcon from "./../../assets/images/games-icon/cycling-icon.png";
import SnookerIcon from "./../../assets/images/games-icon/snooker-icon.png";
import BaseballIcon from "./../../assets/images/games-icon/baseball-icon.png";
import NetballIcon from "./../../assets/images/games-icon/netball-icon.png";
import BasketballIcon from "./../../assets/images/games-icon/basketball-icon.png";
import IceHockeyIcon from "./../../assets/images/games-icon/icehockey-icon.png";
import HandballIcon from "./../../assets/images/games-icon/handball-icon.png";
import DartsIcon from "./../../assets/images/games-icon/darts-icon.png";
import GreyhoundIcon from "./../../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "./../../assets/images/games-icon/politics-icon.png";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec d-none d-md-block">
        <ul>
          <li className="games_link">
            <h6>Quick Links</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports");
                  }}
                >
                  <img src={HomeIcon} alt="Home Icon" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Inplay", {
                      state: { selectedTab: "1" },
                    });
                  }}
                >
                  <img src={InplayIcon} alt="Inplay Icon" />
                  <span>In-play</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/casino/worldcasino");
                  }}
                >
                  <img src={CasinoIcon} alt="Casino Icon" />
                  <span>World Casino</span>
                </div>
              </li>
            </ul>
          </li>

          <li className="games_link">
            <h6>Sports</h6>
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Cricket", {
                      state: { selectedTab: "2" },
                    });
                  }}
                >
                  <img src={CricketIcon} alt="Cricket Icon" />
                  <span>Cricket</span>
                  <div className="sport-event-count badge">
                    {sportsData?.[0]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Soccer", {
                      state: { selectedTab: "3" },
                    });
                  }}
                >
                  <img src={SoccerIcon} alt="Soccer Icon" />
                  <span>Soccer</span>
                  <div className="sport-event-count badge">
                    {" "}
                    {sportsData?.[1]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sportsbook/Tennis", {
                      state: { selectedTab: "4" },
                    });
                  }}
                >
                  <img src={TennisIcon} alt=" Icon" />
                  <span>Tennis</span>
                  <div className="sport-event-count badge">
                    {" "}
                    {sportsData?.[2]?.values.length}
                  </div>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Golf">
                  <img src={GolfIcon} alt="Golf Icon" />
                  <span>Golf</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Rugby">
                  <img src={RugbyIcon} alt="Rugby Icon" />
                  <span>Rugby</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Boxing">
                  <img src={BoxingIcon} alt="Boxing Icon" />
                  <span>Boxing</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Horse%20Racing">
                  <img src={horseracingIcon} alt="horseracing Icon" />
                  <span>horseracing</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Esports">
                  <img src={EsportsIcon} alt="Esports Icon" />
                  <span>Esports</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Volleyball">
                  <img src={VolleyballIcon} alt="Volleyball Icon" />
                  <span>Volleyball</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Cycling">
                  <img src={CyclingIcon} alt="Cycling Icon" />
                  <span>Cycling</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Snooker">
                  <img src={SnookerIcon} alt="Snooker Icon" />
                  <span>Snooker</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Baseball">
                  <img src={BaseballIcon} alt="Baseball Icon" />
                  <span>Baseball</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Netball">
                  <img src={NetballIcon} alt="Netball Icon" />
                  <span>Netball</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Basketball">
                  <img src={BasketballIcon} alt="Basketball Icon" />
                  <span>Basketball</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Ice%20Hockey">
                  <img src={IceHockeyIcon} alt="Ice Hockey Icon" />
                  <span>Ice Hockey</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Handball">
                  <img src={HandballIcon} alt="Handball Icon" />
                  <span>Handball</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Darts">
                  <img src={DartsIcon} alt="Darts Icon" />
                  <span>Darts</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Greyhound%20Racing">
                  <img src={GreyhoundIcon} alt="Greyhound Racing Icon" />
                  <span>Greyhound Racing</span>
                </div>
              </li>
              <li>
                <div href="/sportsbook/Politics">
                  <img src={PoliticsIcon} alt="Politics Icon" />
                  <span>Politics</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
